import React from 'react';
import { GetString } from '../common/appFns';
import Classes from './ErrorBoundary.module.css';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	goBackToApp = () => {
		window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'HOME' }));
	};

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className={Classes.errorContainer}>
					<div className={Classes.errorMsg}>
						<h5>{GetString('somethingWentWrong')}!</h5>
						<div>{GetString('pleaseTryAfterSometime')}</div>
					</div>
					<div onClick={this.goBackToApp} className={Classes.homeBtn}>
					{GetString('HOME')}
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
