import React from "react";
import { RotatingLines } from "react-loader-spinner";
import classes from "./Loader.module.scss";

const Loader = (props) => {
  return (
    <div className={classes.loaderContainer}>
      {props.children}
      <div className="dotContainer">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="20"
          visible={true}
        />
        {/* <div className={classes.loadingSpinner}></div> */}
        {/* <div className="dot dot2"></div>
        <div className="dot dot3"></div> */}
      </div>
    </div>
  );
};

export default Loader;
