import { useContext } from "react";
import ManageWorkdaysContext from "../Store/manageWorkdaysContext";
import en from "./en.json";

export const GetString = (str) => {
  const workDaysCtx = useContext(ManageWorkdaysContext);
  const langDetails = workDaysCtx.langDetails;
  let outStr = "";
  if (langDetails[str]) {
    outStr = langDetails[str];
  } else {
    outStr = en[str];
  }
  if (!outStr) {
    outStr = "";
  }
  return outStr;
};
