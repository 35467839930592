import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { ManageWorkdaysContextProvider } from "./Store/manageWorkdaysContext";
import ErrorBoundary from "./pages/ErrorBoundary";
import "./index.scss";

ReactDOM.render(
  <BrowserRouter>
    <ManageWorkdaysContextProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ManageWorkdaysContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
