import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Layout from "./components/layout/Layout";
import "./App.css";
import LoadingSpinner from "./UI/Loader/Loader";

//landing page form app
const Webview = React.lazy(() => import("./pages/Webview"));
// const SuccessLandingPage = React.lazy(() => import('./pages/SuccessLandingPage'));

//hybrid workdays route
const ManageHybridWorkDays = React.lazy(() =>
  import("./pages/ManageWorkSchedule")
);
const SearchColleague = React.lazy(() => import("./pages/SearchColleague"));
// const SearchColleague = React.lazy(() => import('./pages/SearchColleague_copy'));

//booing route
const MapView = React.lazy(() => import("./pages/MapView"));
const Booking = React.lazy(() => import("./pages/Booking"));
const Recommendation = React.lazy(() => import("./pages/AvailabilityDates"));
const DirectReco = React.lazy(() => import("./pages/DirectReco"));
const FloorView = React.lazy(() => import("./pages/FloorView"));
const WayFinding = React.lazy(() => import("./pages/Wayfinding"));

//find Colleague route
const FCWayfinding = React.lazy(() => import("./pages/FCWayfinding"));
const FindColleague = React.lazy(() => import("./pages/FindColleagueUserView"));
const ColleagueFloorView = React.lazy(() =>
  import("./pages/ColleagueFloorView")
);
const RaceCondition = React.lazy(() => import("./pages/RaceCondition"));

//development use clear booking
const CancelBooking = React.lazy(() => import("./pages/CancelBooking"));

function App() {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="centered">
            <LoadingSpinner />
          </div>
        }
      >
        <Switch>
          <Route path="/" exact>
            <Redirect to="/webviewV3/Webview" />
          </Route>
          <Route path="/webviewV3" exact>
            <Redirect to="/webviewV3/Webview" />
          </Route>
          <Route path="/webviewV3/Webview" exact>
            <Webview />
          </Route>
          {/* <Route path='/webviewV3/Success' exact>
						<SuccessLandingPage />
					</Route> */}
          <Route path="/webviewV3/MapView" exact>
            <MapView />
          </Route>
          <Route path="/webviewV3/ManageHybridWorkDays" exact>
            <ManageHybridWorkDays />
          </Route>
          <Route path="/webviewV3/SearchColleague" exact>
            <SearchColleague />
          </Route>
          <Route path="/webviewV3/Booking" exact>
            <Booking />
          </Route>
          <Route path="/webviewV3/FloorView" exact>
            <FloorView />
          </Route>
          <Route path="/webviewV3/ColleagueFloorView" exact>
            <ColleagueFloorView />
          </Route>
          <Route path="/webviewV3/Recommendation" exact>
            <Recommendation />
          </Route>
          <Route path="/webviewV3/DirectReco" exact>
            <DirectReco />
          </Route>
          <Route path="/webviewV3/WayFinding" exact>
            <WayFinding />
          </Route>
          <Route path="/webviewV3/FindColleague" exact>
            <FindColleague />
          </Route>
          <Route path="/webviewV3/FCWayfinding" exact>
            <FCWayfinding />
          </Route>
          <Route path="/webviewV3/CancelBooking" exact>
            <CancelBooking />
          </Route>
          <Route path="/webviewV3/RaceCondition" exact>
            <RaceCondition />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
