import React, { useState } from 'react';

const ManageWorkdaysContext = React.createContext({
	appConfig: {},
	userSchedule: {},
	selectedDays: {},
	isUserDriven: true,
	calendarType: '',
	hybridWorkdaysConfig: {},
	moduleConfig: {},
	userAvailabilityCount: {},
	colleagueSchedule: {},
	userSettings: {},
	langDetails: {},
	locale: '',
	addAppConfig: (config) => {},
	addUserSettings: (config) => {},
	addModuleConfig: (config) => {},
	toggleCalendarType: (type) => {},
	addUserSchedule: (data) => {},
	addColleagueSchedule: (data) => {},
	addSelectedDays: (days) => {},
	addUserDriven: (type) => {},
	saveHybridWorkdaysConfig: (config) => {},
	saveAvailabilityCount: (data) => {},
	addLangDetails: (data) => {},
	addLocale: (locale) => {},
});

export const ManageWorkdaysContextProvider = (props) => {
	const [appConfigData, setAppConfig] = useState({});
	const [langDetailsData, setLangDetails] = useState({});
	const [localeData, setLocaleDetails] = useState({});
	const [userSettingsData, setUserSettings] = useState({});
	const [moduleConfigData, setModuleConfig] = useState(null);
	const [calendarType, setCalendarType] = useState('');
	const [userSchedule, setUserSchedule] = useState([]);
	const [colleagueSchedule, setColleagueSchedule] = useState([]);
	const [userAvailabilityCount, setAvailabilityCount] = useState([]);
	const [selectedDays, setSelectedDays] = useState([]);
	const [isUserDriven, setUserDriven] = useState(true);
	const [hybridWorkdaysConfig, setHybridWorkdaysConfig] = useState(true);

	const addAppConfig = (config) => {
		setAppConfig(config);
	};
	const addLangDetails = (aData) => {
		setLangDetails(aData);
	};
	const addLocaleDetails = (locale) => {
		setLocaleDetails(locale);
	};
	const addUserSettings = (config) => {
		setUserSettings(config);
	};
	const toggleCalendarType = (type) => {
		setCalendarType(type);
	};
	const addUserSchedule = (data, refresh = false) => {
		// setUserSchedule(data);
		if (!!refresh) {
			setUserSchedule(data);
		} else {
			setUserSchedule((prevState) => {
				return { ...prevState, ...data };
			});
		}
	};
	const addColleagueSchedule = (data) => {
		// setUserSchedule((prevState) => [...prevState, ...data]);
		setColleagueSchedule(data);
	};
	const saveAvailabilityCount = (data, refresh = false) => {
		// setUserSchedule((prevState) => [...prevState, ...data]);
		// setAvailabilityCount(data);
		if (!!refresh) {
			setAvailabilityCount(data);
		} else {
			setAvailabilityCount((prev) => {
				return { ...prev, ...data };
			});
		}
	};
	const addSelectedDays = (days) => {
		days.sort((a, b) => a.dateLabel - b.dateLabel);
		setSelectedDays(days);
	};
	const addUserDriven = (type) => {
		setUserDriven(type);
	};
	const saveHybridWorkdaysConfig = (config) => {
		setHybridWorkdaysConfig(config);
	};
	const addModuleConfig = (data) => {
		setModuleConfig(data);
	};
	return (
		<ManageWorkdaysContext.Provider
			value={{
				appConfig: appConfigData,
				langDetails: langDetailsData,
				locale: localeData,
				userSettings: userSettingsData,
				moduleConfig: moduleConfigData,
				moduleConfigData: moduleConfigData,
				userSchedule: userSchedule,
				colleagueSchedule: colleagueSchedule,
				selectedDays: selectedDays,
				isUserDriven: isUserDriven,
				calendarType: calendarType,
				hybridWorkdaysConfig: hybridWorkdaysConfig,
				userAvailabilityCount: userAvailabilityCount,
				addAppConfig: addAppConfig,
				addUserSettings: addUserSettings,
				toggleCalendarType: toggleCalendarType,
				addUserSchedule: addUserSchedule,
				addColleagueSchedule: addColleagueSchedule,
				addSelectedDays: addSelectedDays,
				saveAvailabilityCount: saveAvailabilityCount,
				addUserDriven: addUserDriven,
				saveHybridWorkdaysConfig: saveHybridWorkdaysConfig,
				addModuleConfig: addModuleConfig,
				addLangDetails: addLangDetails,
				addLocaleDetails: addLocaleDetails,
			}}>
			{props.children}
		</ManageWorkdaysContext.Provider>
	);
};
export default ManageWorkdaysContext;
