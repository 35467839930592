import React from "react";

const Layout = (props) => {
  return (
      <div className="page-container">
        {props.children}
      </div>
  );
};

export default Layout;
